import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'
import { Navbar, Nav, Button, Container, Card, Row, Col, Image, Carousel } from 'react-bootstrap'
import backgroundImage from '../../assets/images/Eazeeboxbg.PNG'
import Footer from './Footer'
import backgroundImageFooter from '../../assets/images/Rectangle 13.png'
import '../../assets/eazeebox.css'

const Team = () => {
  const reelThumbnailUrl =
    'https://scontent.cdninstagram.com/v/t51.2885-15/359731991_1234567890_n.jpg?stp=dst-jpg&_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=abcd1234&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_Ax12345abcdef&oe=64A12345' // Replace with your reel thumbnail URL

  const reelLink = 'https://www.instagram.com/reel/C-ro8YOC91K/' // The link to the Instagram reel

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768) // Mobile threshold (adjust if needed)
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check when component mounts

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  function Header() {
    return (
      <Navbar expand="lg" className="bg-transparent">
        <Container>
          {/* Navbar Toggle Button (Visible on mobile) */}
          {isMobile && (
            <Navbar.Brand href="/" className="mx-auto">
              <img
                src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/Eazeebox+white+logo+3.png"
                className="d-block"
                style={{ maxWidth: '180px' }} // Adjust the size if needed
              />
            </Navbar.Brand>
          )}
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="d-lg-none" // Display only on mobile
            style={{ maxWidth: '60px' }}
          />

          {/* Collapsible Navbar */}
          <Navbar.Collapse
            id="navbarScroll"
            className="justify-content-center align-items-center w-100"
          >
            {/* Centered Logo for Desktop */}
            {!isMobile && (
              <Navbar.Brand href="#" className="mx-auto">
                <img
                  src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/Eazeebox+white+logo+3.png"
                  alt="Eazeebox Logo"
                  className="d-block"
                  style={{ maxWidth: '180px' }} // Adjust the size if needed
                />
              </Navbar.Brand>
            )}

            {/* Navigation Links (Centered) */}
            <Nav className="mx-auto">
              <Nav.Item>
                <Link to="/OurStory" className="nav-link text-white">
                  Our Story
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/Team" className="nav-link text-white">
                  Meet the Team
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/OurPartnerships" className="nav-link text-white disabled">
                  Our Partnerships
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/ContactUs" className="nav-link text-white disabled">
                  Contact Us
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Navbar */}
        {Header()}

        {/* Hero Section */}

        <Container
          size="lg"
          style={{
            border: '0px',
            boxShadow: 'none',
            justifyContent: 'center',
            textAlign: 'center',
            height: 'auto',
            padding: '1rem',
          }}
        >
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-start', // Align content to the left
              alignItems: 'flex-start', // Align items to the top
              borderBottom: '0px',
              padding: '1rem', // Optional: Add some padding
            }}
          >
            <Col
              xs={12} // Full width on small screens
              md={6} // Half width on medium screens
              style={{
                marginBottom: '1rem',
                maxWidth: '50%', // Ensure it doesn't exceed 50% on medium+ screens
                textAlign: 'left', // Align text to the left
              }}
            >
              <h1
                className="responsive-text"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  textAlign: 'left',
                  color: '#fbfafa',
                  fontSize: 'clamp(1.5rem, 5vw, 3rem)', // Responsive font size
                }}
              >
                Meet the
              </h1>
              <h1
                className="responsive-text"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  textAlign: 'left',
                  color: '#fbfafa',
                  fontSize: 'clamp(1.5rem, 5vw, 3rem)', // Responsive font size
                }}
              >
                Eazeebox
              </h1>
              <h1
                className="responsive-text"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  textAlign: 'left',
                  color: '#fbfafa',
                  fontSize: 'clamp(1.5rem, 5vw, 3rem)', // Responsive font size
                }}
              >
                Team
              </h1>
              <p
                className="responsive-p-text"
                style={{
                  margin: '0',
                  color: '#fbfafa',
                  fontSize: 'clamp(0.9rem, 3vw, 1.2rem)', // Responsive font size
                  marginTop: '5rem',
                }}
              >
                Four co-founders & one shared vision – to make life easy for the electrical
                retailer. If that means disrupt the current electrical distribution, so be it. The
                electrical retailer isn’t going away any time soon, and we’re lighting the way for a
                brighter future for the retailer.
              </p>
            </Col>
          </Row>

          <style>
            {`
          @media (max-width: 768px) {
            h2.service-responsive-text-header {
              font-size: 1.5rem; /* Smaller heading for mobile */
            }
            p.service-responsive-text {
              font-size: 0.9rem; /* Smaller font for description */
            }
            button {
              width: 100%; /* Full-width button for mobile */
            }
          }
          @media (min-width: 768px) and (max-width: 992px) {
            h2.service-responsive-text-header {
              font-size: 1.6rem; /* Slightly smaller heading for tablets */
            }
          }
        `}
          </style>
        </Container>
      </div>

      {/* Team Section */}
      <div>
        <Container
          size="lg"
          style={{
            border: '0px',
            boxShadow: 'none',
            justifyContent: 'center',
            textAlign: 'center',
            height: 'auto',
            padding: '1rem',
          }}
        >
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10vh', // Adjust vertical spacing
              borderBottom: '0px',
            }}
          >
            {/* Image Column */}
            <Col
              xs={12}
              md={6}
              style={{
                display: 'flex-start',
                justifyContent: 'left', // Center image horizontally
                alignItems: 'left', // Center image vertically
              }}
            >
              <img
                src={require('../../assets/images/CEO.png')}
                alt="EazeeBox Logo"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  display: 'flex-start',
                  justifyContent: 'left', // Center image horizontally
                  alignItems: 'left', // Center image vertically
                }}
              />
            </Col>
            {/* Text Column */}
            <Col xs={12} md={6} style={{ marginBottom: '1rem' }}>
              <div style={{ textAlign: 'left' }}>
                <h2
                  className="service-responsive-text-header"
                  style={{
                    fontWeight: 'bold',
                    margin: '0',
                    fontFamily: 'Rubik, sans-serif',
                    color: '#ED324D',
                    fontSize: '1.8rem',
                  }}
                >
                  Srinivas Shanbhogue
                </h2>
                <div
                  style={{
                    width: '100%',
                    height: '3px',
                    backgroundImage: 'linear-gradient(to right, #ED324D, #0035C3)',
                    borderRadius: '2px',
                    margin: '5px 0',
                  }}
                />
                <h3
                  style={{
                    margin: '0',
                    color: '#ED324D',
                    fontFamily: 'Rubik, sans-serif',
                    fontSize: '1.5rem',
                  }}
                >
                  CEO
                </h3>
                <p
                  className="service-responsive-text"
                  style={{
                    margin: '0.5rem 0',
                    color: '#949494',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                  }}
                >
                  Industry insider and veteran. Set up and ran the entire retail distribution for a
                  global electric MNC in India. Specialist in B2B with first hand know how of the
                  electrical distribution landscape in India.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container
          size="lg"
          style={{
            border: '0px',
            boxShadow: 'none',
            justifyContent: 'center',
            textAlign: 'center',
            height: 'auto',
            padding: '1rem',
          }}
        >
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10vh', // Adjust vertical spacing
              borderBottom: '0px',
            }}
          >
            {/* Text Column */}
            <Col xs={12} md={6} style={{ marginBottom: '1rem' }}>
              <div style={{ textAlign: 'left' }}>
                <h2
                  className="service-responsive-text-header"
                  style={{
                    fontWeight: 'bold',
                    margin: '0',
                    fontFamily: 'Rubik, sans-serif',
                    color: '#0035C3',
                    fontSize: '1.8rem',
                  }}
                >
                  Vittal VB
                </h2>
                <div
                  style={{
                    width: '100%',
                    height: '3px',
                    backgroundImage: 'linear-gradient(to right, #ED324D, #0035C3)',
                    borderRadius: '2px',
                    margin: '5px 0',
                  }}
                />
                <h3
                  style={{
                    margin: '0',
                    color: '#0035C3',
                    fontFamily: 'Rubik, sans-serif',
                    fontSize: '1.5rem',
                  }}
                >
                  COO
                </h3>
                <p
                  className="service-responsive-text"
                  style={{
                    margin: '0.5rem 0',
                    color: '#949494',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                  }}
                >
                  Passionate leader with rich experience in managing large P&Ls across multiple
                  roles in Schneider and Wipro. Sales leader turned entrepreneur with a successful
                  track record turning Lightingale – an IOT for offices company – into a leading
                  commercial IOT company in India.
                </p>
              </div>
            </Col>

            {/* Image Column */}
            <Col
              xs={12}
              md={6}
              style={{
                display: 'flex-start',
                justifyContent: 'left', // Center image horizontally
                alignItems: 'left', // Center image vertically
              }}
            >
              <img
                src={require('../../assets/images/COO.png')}
                alt="EazeeBox Logo"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  display: 'flex-start',
                  justifyContent: 'left', // Center image horizontally
                  alignItems: 'left', // Center image vertically
                }}
              />
            </Col>
          </Row>
        </Container>

        <Container
          size="lg"
          style={{
            border: '0px',
            boxShadow: 'none',
            justifyContent: 'center',
            textAlign: 'center',
            height: 'auto',
            padding: '1rem',
          }}
        >
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10vh', // Adjust vertical spacing
              borderBottom: '0px',
            }}
          >
            {/* Image Column */}
            <Col
              xs={12}
              md={6}
              style={{
                display: 'flex-start',
                justifyContent: 'left', // Center image horizontally
                alignItems: 'left', // Center image vertically
              }}
            >
              <img
                src={require('../../assets/images/CTO.png')}
                alt="EazeeBox Logo"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  display: 'flex-start',
                  justifyContent: 'left', // Center image horizontally
                  alignItems: 'left', // Center image vertically
                }}
              />
            </Col>
            {/* Text Column */}
            <Col xs={12} md={6} style={{ marginBottom: '1rem' }}>
              <div style={{ textAlign: 'left' }}>
                <h2
                  className="service-responsive-text-header"
                  style={{
                    fontWeight: 'bold',
                    margin: '0',
                    fontFamily: 'Rubik, sans-serif',
                    color: '#ED324D',
                    fontSize: '1.8rem',
                  }}
                >
                  Phalgun Lanka
                </h2>
                <div
                  style={{
                    width: '100%',
                    height: '3px',
                    backgroundImage: 'linear-gradient(to right, #ED324D, #0035C3)',
                    borderRadius: '2px',
                    margin: '5px 0',
                  }}
                />
                <h3
                  style={{
                    margin: '0',
                    color: '#ED324D',
                    fontFamily: 'Rubik, sans-serif',
                    fontSize: '1.5rem',
                  }}
                >
                  CTO
                </h3>
                <p
                  className="service-responsive-text"
                  style={{
                    margin: '0.5rem 0',
                    color: '#949494',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                  }}
                >
                  Software engineer forced to turn to entrepreneurship at 23. Started Lightingale,
                  an IoT for offices company, which now is a market leader in the commercial IoT
                  space. Briefly set up and ran Locally, a cloud kitchen focussed on local
                  ingredients, produce, & recipes.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container
          size="lg"
          style={{
            border: '0px',
            boxShadow: 'none',
            justifyContent: 'center',
            textAlign: 'center',
            height: 'auto',
            padding: '1rem',
          }}
        >
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10vh', // Adjust vertical spacing
              borderBottom: '0px',
            }}
          >
            {/* Text Column */}
            <Col xs={12} md={6} style={{ marginBottom: '1rem' }}>
              <div style={{ textAlign: 'left' }}>
                <h2
                  className="service-responsive-text-header"
                  style={{
                    fontWeight: 'bold',
                    margin: '0',
                    fontFamily: 'Rubik, sans-serif',
                    color: '#0035C3',
                    fontSize: '1.8rem',
                  }}
                >
                  Ravichandra GB
                </h2>
                <div
                  style={{
                    width: '100%',
                    height: '3px',
                    backgroundImage: 'linear-gradient(to right, #ED324D, #0035C3)',
                    borderRadius: '2px',
                    margin: '5px 0',
                  }}
                />
                <h3
                  style={{
                    margin: '0',
                    color: '#0035C3',
                    fontFamily: 'Rubik, sans-serif',
                    fontSize: '1.5rem',
                  }}
                >
                  CHRO
                </h3>
                <p
                  className="service-responsive-text"
                  style={{
                    margin: '0.5rem 0',
                    color: '#949494',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                  }}
                >
                  Global executive with rich experience in General Management. Strong leadership
                  connect across Fortune 100 companies. Proven track record in building alliances
                  and managing large deals.
                </p>
              </div>
            </Col>

            {/* Image Column */}
            <Col
              xs={12}
              md={6}
              style={{
                display: 'flex-start',
                justifyContent: 'left', // Center image horizontally
                alignItems: 'left', // Center image vertically
              }}
            >
              <img
                src={require('../../assets/images/CHRO.png')}
                alt="EazeeBox Logo"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  display: 'flex-start',
                  justifyContent: 'left', // Center image horizontally
                  alignItems: 'left', // Center image vertically
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default Team
