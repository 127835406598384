// Set default value for urlHost
let urlHost = 'https://eb.dev-eazeebox.com/'
let domainHost = 'https://eb.dev-eazeebox.com/'
let websocketHost = 'wss://eb.dev-eazeebox.com/'
// let urlHost = 'http://localhost:8000/'
// let domainHost = 'http://localhost:3000/'
// let websocketHost = 'ws://localhost:8000/'

// let urlHost = 'http://192.168.1.25:8000/'
// let domainHost = 'http://172.20.10.6:3000/' 172.20.10.6
// let urlHost = 'http://172.20.10.6:8000/'

const disabledDates = [new Date(2000, 0, 1), new Date()]
// Export urlHost for use in other moduleslet urlHost = 'http://192.168.1.5:8000/'
module.exports = { urlHost, domainHost, disabledDates, websocketHost }
