import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useNavigate } from 'react-router-dom'
import { Navbar, Nav, Button, Container, Card, Row, Col, Image, Carousel } from 'react-bootstrap'
import backgroundImage from '../../assets/images/Eazeeboxbg.PNG'
import backgroundImageFooter from '../../assets/images/Rectangle 13.png'
import '../../assets/eazeebox.css'
const Footer = () => {
  const reelThumbnailUrl =
    'https://scontent.cdninstagram.com/v/t51.2885-15/359731991_1234567890_n.jpg?stp=dst-jpg&_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=abcd1234&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_Ax12345abcdef&oe=64A12345' // Replace with your reel thumbnail URL

  const reelLink = 'https://www.instagram.com/reel/C-ro8YOC91K/' // The link to the Instagram reel

  const [isMobile, setIsMobile] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImageFooter})`,
          backgroundSize: 'cover', // Ensure the image covers the width while maintaining aspect ratio
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center bottom', // Align background to the footer
          minHeight: '120vh', // Full viewport height
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Hero Section */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            textAlign: 'left',
            color: 'white',
            maxWidth: '60%',
            margin: '8vh',
            marginBottom: '0px',
            marginTop: '50vh',
          }}
        >
          <h5
            style={{
              fontFamily: 'Rubic, sans-serif',
              fontWeight: 600, // 600 corresponds to the semibold weight
              margin: '0',
              textAlign: 'left',
              color: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            Market Ka Naya Naam{' '}
          </h5>

          <h5
            className="pt-3"
            style={{
              fontFamily: 'Rubic, sans-serif',
              fontWeight: 'bold',
              margin: '0',
              textAlign: 'left',
              color: 'rgba(255, 255, 255, 0.7)',
              fontSize: '2.5rem', // Adjust font size for better readability
            }}
          >
            A Market Leader In
          </h5>
          <h5
            style={{
              fontFamily: 'Rubic, sans-serif',
              fontWeight: 'bold',
              margin: '0',
              textAlign: 'left',
              color: 'rgba(255, 255, 255, 0.7)',
              fontSize: '2.5rem', // Adjust font size for better readability
            }}
          >
            The Electrical Industry
          </h5>
          <p
            className="pt-3"
            style={{
              fontFamily: 'Rubic, sans-serif',
              margin: '0',
              textAlign: 'left',
              color: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            Eazeebox is all about making life easier for electrical retailers across India. From
            small shops to big businesses, we’re proud to be a go-to name in the FMEG industry,
            helping them grow every step of the way.
          </p>
          <Row className="pt-5" style={{ borderBottom: '2px' }}>
            <Col className="p-3" style={{ minWidth: '200px' }} xs={12} sm={6} md={3}>
              <Col>| 4000+</Col>
              <Col> Customers</Col>
            </Col>
            <Col className="p-3" style={{ minWidth: '200px' }} xs={12} sm={6} md={3}>
              <Col>| 20,000+</Col>
              <Col> Cart Delivered</Col>
            </Col>
            <Col className="p-3" style={{ minWidth: '200px' }} xs={12} sm={6} md={3}>
              <Col>| 500+</Col>
              <Col> Micromarkets</Col>
            </Col>
            <Col className="p-3" style={{ minWidth: '200px' }} xs={12} sm={6} md={3}>
              <Col>| 25,000+</Col>
              <Col> Product Basket</Col>
            </Col>
          </Row>
        </div>

        <style>
          {`
      @media (max-width: 768px) {
        div {
          background-size: contain; /* Optimize image size for mobile */
        }
        h1 {
          font-size: 1.8rem; /* Smaller headline for mobile */
        }
        p {
          font-size: 0.9rem; /* Adjust text size */
        }
      }
      @media (min-width: 768px) and (max-width: 1200px) {
        h1 {
          font-size: 2rem; /* Slightly smaller font for tablets */
        }
      }
    `}
        </style>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          textAlign: 'left',
          margin: '10%',
        }}
      >
        <p
          className="p-3"
          style={{
            fontFamily: 'Rubic, sans-serif',
            margin: '0',
            textAlign: 'left',
            color: 'rgba(30, 18, 18, 0.7)',
          }}
        >
          <a
            target="blank"
            href="https://www.instagram.com/eazeebox?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          >
            Follow us on{' '}
            <img
              src={require('../../assets/images/Instagram_icon 3.png')}
              style={{
                maxWidth: '100%', // Ensures the image scales properly
                height: 'auto', // Maintains aspect ratio
              }}
            />{' '}
            @eazeebox &{' '}
          </a>
          <a href="https://www.linkedin.com/company/eazeebox/posts/?feedView=all" target="blank">
            <img
              src={require('../../assets/images/Linkedin Logo 1.png')}
              style={{
                maxWidth: '100%', // Ensures the image scales properly
                height: 'auto', // Maintains aspect ratio
              }}
            />
            Linkedin
          </a>
        </p>
        <Col xs={12} sm={6} md={6} className="px-3  mb-3">
          Get started with the Eazeebox App now
        </Col>

        {/* Subheading Section */}
        <Col xs={12} sm={6} md={3} className="px-3  mb-3">
          <p>Click the link below</p>
        </Col>

        {/* Google Play Image */}
        <Col xs={12} sm={6} md={3} className="px-3  mb-3">
          {' '}
          <a
            target="blank"
            href="https://play.google.com/store/apps/details?id=com.eazeeboxAndroid&hl=en_IN&pli=1"
          >
            <img
              src={require('../../assets/images/get it on google play 1.png')}
              alt="Google Play"
              style={{
                maxWidth: '100%', // Ensures the image scales properly
                height: 'auto', // Maintains aspect ratio
              }}
            />{' '}
          </a>
        </Col>

        {/* Footer Section */}
        <Row className="px-3 align-items-center no-gutters" style={{ marginBottom: '1%' }}>
          <Col xs="auto" className="d-flex">
            <img
              src={require('../../assets/images/Vector.png')}
              alt="Eazeebox Logo"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Col>
          <Col xs="auto" className="d-flex">
            <p className="mt-0 mb-0 ms-2">2025 Eazeebox Pvt. Ltd.</p>
          </Col>
          <Col xs="auto" className="d-flex" onClick={() => navigate('/Privacy')}>
            <p className="mt-0 mb-0 ms-2">Privacy Policy</p>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Footer
