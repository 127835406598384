import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { Navbar, Nav, Button, Container, Card, Row, Col, Image, Carousel } from 'react-bootstrap'
import backgroundImage from '../../assets/images/Eazeeboxbg.PNG'
import Footer from './Footer'
import backgroundImageFooter from '../../assets/images/Rectangle 13.png'
import '../../assets/eazeebox.css'
import VideoAutoplay from './VideoSection'
const EazeeBox = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 0.5, // Trigger when 50% of the element is in view
  })
  const reelThumbnailUrl =
    'https://scontent.cdninstagram.com/v/t51.2885-15/359731991_1234567890_n.jpg?stp=dst-jpg&_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=abcd1234&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_Ax12345abcdef&oe=64A12345' // Replace with your reel thumbnail URL

  const reelLink = 'https://www.instagram.com/reel/C-ro8YOC91K/' // The link to the Instagram reel

  const [isMobile, setIsMobile] = useState(true)
  const [isAnimated, setIsAnimated] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.element-to-animate')

      elements.forEach((element) => {
        const position = element.getBoundingClientRect()

        // Print the class names starting with "animate__"
        const animateClasses = Array.from(element.classList).filter((className) =>
          className.startsWith('animate__'),
        )

        // Remove existing animation classes that start with "animate__"

        // Check if element is in the viewport
        if (position.top >= 0 && position.bottom <= window.innerHeight - 20) {
          // Add the new animation classes
          // animateClasses.forEach((className) => {
          //   element.classList.remove(className)
          // })
          element.classList.add('animate__animated', 'animate__backInRight')
        }
      })
    }

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  function BrandLogos() {
    const [showAll, setShowAll] = useState(false)

    const logos = [
      '2560px-AO_Smith_logo.svg 1.png',
      '34b5c964b953ffa16d40b10c7b6c3b20 1.png',
      'ABB logo 1.png',
      'Achem Logo 1.png',
      'almonard Logo 1.png',
      'Anchor by Panasonic logo 1.png',
      'Atomberg Logo 1.png',
      'Bajaj Electricals 1.png',
      'Bentex logo 1.png',
      'Crompton-Logo-Vector 1.png',
      'deco logo 2.png',
      'dewon electric logo 1.png',
      'D-Link-Logo.wine 1.png',
      'download (1) 1.png',
      'Finolex_Logo.svg (1) 2.png',
      'GM Logo 1.png',
      'Goldmedal logo 1.png',
      'GreatWhite Electricals 1.png',
      'Hager 1.png',
      'havells 1.png',
      'Honeywell 1.png',
      'indoasian_logo-removebg-preview 1.png',
      'Johnson logo 1.png',
      'KEI logo 1.png',
      'Legrand-Logo 1.png',
      'logo (1) 1.png',
      'logo 1.png',
      'logo (2) 1.png',
      'Luker 1.png',
      'Luminous_Logo-removebg-preview 1.png',
      'NORTEK PNG 1.png',
      'Orbit logo 1.png',
      'ORIENT LOGO 1.png',
      'Phillips-Logo 1.png',
      'racold 1.png',
      'relicell_logo-removebg-preview 1.png',
      'RR_Electric_logo-removebg-preview 1.png',
      'Schneider Electric 1.png',
      'Siemens-Logo 1.png',
      'tvs-green-logo 1.png',
      'usha-logo (1) 1.png',
      'V-Guard-Logo-PNG@zeevector 1.png',
    ]

    const visibleLogos = showAll ? logos : logos.slice(0, isMobile ? 10 : 50)
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768) // Mobile threshold (adjust if needed)
      }

      window.addEventListener('resize', handleResize)
      handleResize() // Initial check when component mounts

      return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
      <div>
        <Row className="justify-content-center align-items-center " style={{ borderBottom: '0px' }}>
          {visibleLogos.map((logo, index) => (
            <Col
              xs={6}
              md={2}
              key={index}
              className="d-flex justify-content-center align-items-center mt-2 "
            >
              <Image
                src={require(`../../assets/brand_logo/${logo}`)}
                alt={`Brand Logo ${index + 1}`}
                style={{ width: '80%', padding: '0px' }}
                rounded
                className="element-to-animate animate__animated animate__backInRight"
              />
            </Col>
          ))}
        </Row>

        {/* Show "See More" Button only if not showing all logos */}
        {!showAll & isMobile && (
          <div className="float-end">
            <Button
              onClick={() => setShowAll(true)}
              variant="link"
              className="text-muted float-end"
            >
              {'See More >'}
            </Button>
          </div>
        )}
      </div>
    )
  }
  function Header() {
    return (
      <Navbar expand="lg" className="bg-transparent">
        <Container>
          {/* Navbar Toggle Button (Visible on mobile) */}
          {!isMobile && (
            <Navbar.Brand href="/" className="mx-auto">
              <img
                src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/Eazeebox+white+logo+3.png"
                className="d-block"
                style={{ maxWidth: '180px' }} // Adjust the size if needed
              />
            </Navbar.Brand>
          )}
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="d-lg-none" // Display only on mobile
            style={{ maxWidth: '60px' }}
          />

          {/* Collapsible Navbar */}
          <Navbar.Collapse
            id="navbarScroll"
            className="justify-content-center align-items-center w-100"
          >
            {/* Centered Logo for Desktop */}
            {isMobile && (
              <Navbar.Brand href="/" className="mx-auto">
                <img
                  src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/Eazeebox+white+logo+3.png"
                  alt="Eazeebox Logo"
                  className="d-block"
                  style={{ maxWidth: '180px' }} // Adjust the size if needed
                />
              </Navbar.Brand>
            )}

            {/* Navigation Links (Centered) */}
            <Nav className="mx-auto">
              <Nav.Item>
                <Link to="/OurStory" className="nav-link text-white">
                  Our Story
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/Team" className="nav-link text-white">
                  Meet the Team
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/OurPartnerships" className="nav-link text-white disabled">
                  Our Partnerships
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/ContactUs" className="nav-link text-white disabled">
                  Contact Us
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }

  const playstoreURL = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.eazeeboxAndroid&hl=en_IN',
      '_blank',
    )
  }

  useEffect(() => {
    // Load Instagram embed script
    const script = document.createElement('script')
    script.async = true
    script.src = '//www.instagram.com/embed.js'
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script) // Cleanup script on unmount
    }
  }, [])

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          minHeight: '90vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Navbar */}
        {Header()}

        {/* Hero Section */}

        <Container
          size="lg"
          style={{
            border: '0px',
            boxShadow: 'none',
            justifyContent: 'center',
            height: 'auto',
            padding: '1rem',
            minWidth: '100%', // Full width by default
          }}
        >
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: '0px',
            }}
          >
            {/* Text Column */}
            <Col
              className="p-4"
              size="lg"
              style={{
                textAlign: 'left',
              }}
            >
              <h1
                className="responsive-text animate__animated animate__backInRight"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  textAlign: 'left',
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontSize: 'clamp(1.5rem, 5vw, 3rem)', // Responsive font size
                }}
              >
                India’s 1st B2B Platform for Electrical Products{' '}
                <span
                  className="responsive-text animate__animated animate__backInRight"
                  style={{
                    fontWeight: 'bold',
                    margin: '0',
                    textAlign: 'left',
                    color: '#fafafa',
                    fontSize: 'clamp(2.5rem, 5vw, 7rem)', // Responsive font size
                  }}
                >
                  made for Electrical Retailers
                </span>
              </h1>

              <p
                className="responsive-p-text  animate__animated animate__backInRight"
                style={{
                  margin: '0',
                  color: '#f2e5e5',
                  textAlign: 'left',
                  marginTop: '2rem',
                  fontSize: 'clamp(1rem, 3vw, 1.2rem)', // Responsive font size
                }}
              >
                Say hello to Eazeebox! Order electrical supplies in three easy steps. Click, order,
                & get it delivery in less than 4 hours.
              </p>
              <Button
                className=" animate__animated animate__backInRight"
                style={{
                  backgroundColor: '#ED334E',
                  border: 'none',
                  borderRadius: '30px',
                  marginTop: '3rem',
                  fontSize: 'clamp(0.9rem, 2.5vw, 1.2rem)', // Responsive button text size
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease, color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#0035C3')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = '#ED334E')}
                onClick={playstoreURL}
              >
                {'Download the App >'}
              </Button>
            </Col>

            {/* Image Column */}
            <Col
              xs={12}
              md={7}
              className="image-column p-4"
              style={{
                maxWidth: '90%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                right: '0',
              }}
            >
              <img
                src={require('../../assets/images/Group 1-2.png')}
                alt="EazeeBox Logo"
                className="animate__animated animate__backInRight"
                style={{
                  minWidth: '80%',
                  borderRadius: '10px',
                  marginRight: '0',
                  zIndex: 1,
                }}
              />
              <img
                src={require('../../assets/images/Appppp.png')}
                alt="EazeeBox App"
                className=" animate__animated animate__backInDown"
                style={{
                  borderRadius: '10px',
                  position: 'absolute',
                  top: '15%',
                  left: '0',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 2,
                }}
              />
            </Col>
          </Row>

          {/* Media queries for responsiveness across all devices */}
          <style>
            {`
      /* Large screens (desktops) */
      @media (min-width: 1200px) {
        .image-column img {
          max-width: 60%; /* Reduce image width */
        }
        .responsive-text {
          font-size: clamp(1.8rem, 5vw, 3.5rem); /* Slightly larger text on large screens */
        }
        .responsive-p-text {
          font-size: clamp(1rem, 3vw, 1.4rem); /* Slightly larger paragraph text */
        }
      }

      /* Tablets */
      @media (max-width: 1200px) and (min-width: 768px) {
        .image-column img {
          max-width: 60%; /* Reduce image width */
        }
        .responsive-text {
          font-size: clamp(1.5rem, 4.5vw, 3rem); /* Adjust text size */
        }
        .responsive-p-text {
          font-size: clamp(0.9rem, 3vw, 1.3rem); /* Adjust text size */
        }
      }

      /* Mobile devices */
      @media (max-width: 768px) {
        .image-column img {
          max-width: 90%; /* Reduce image width */
        }
        .responsive-text {
          font-size: clamp(1.2rem, 5vw, 2.5rem); /* Adjust text size */
        }
        .responsive-p-text {
          font-size: clamp(0.8rem, 3vw, 1.2rem); /* Adjust text size */
        }
        .responsive-button {
          font-size: clamp(0.8rem, 3vw, 1rem); /* Adjust button text size */
        }
      }

      /* Small mobile devices */
      @media (max-width: 480px) {
        .image-column img {
          max-width: 95%; /* Further reduce image width */
        }
        .responsive-text {
          font-size: clamp(1rem, 5vw, 2rem); /* Adjust text size */
        }
        .responsive-p-text {
          font-size: clamp(0.7rem, 3vw, 1.1rem); /* Adjust text size */
        }
      }
    `}
          </style>
        </Container>
      </div>
      {/* Gallery Section */}
      <Container
        size="lg"
        className="brands-container "
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'space-evenly',
        }}
      >
        <Card.Title
          style={{ fontSize: '1.5rem' }}
          className="text-muted element-to-animate animate__animated animate__backInRight"
        >
          XB Business Partnerships
        </Card.Title>
        <Container
          style={{
            border: '0px',
            boxShadow: 'none',
            justifyContent: 'space-evenly',
            minWidth: '90%',
            borderBottom: '0px',
          }}
        >
          {BrandLogos()}
        </Container>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: '50%',
          padding: '1rem', // Padding for smaller screens
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '0px',
            marginTop: '20vh',
          }}
        >
          <VideoAutoplay />
        </Row>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={6} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#0035C3',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted for responsiveness
              }}
            >
              Eazee to Order
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '0.5rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              With the Eazeebox app, placing an order is super simple. Explore our 20,000 SKU
              digital catalogue featuring all the products and brands you need, at your fingertips.
            </p>
            <Button
              className="element-to-animate animate__animated animate__backInRight"
              style={{
                backgroundColor: '#ED334E',
                border: 'none',
                borderRadius: '30px',
                padding: '10px 20px',
                fontSize: 'clamp(0.9rem, 2.5vw, 1.2rem)', // Responsive button text size
                fontWeight: 'bold',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth transition for hover effect
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#0035C3')} // Hover background color change
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#ED334E')} // Revert to original background
              onClick={playstoreURL}
            >
              {'Download The App >'}
            </Button>
          </Col>
          <Col
            xs={12}
            md={6}
            style={{ display: 'flex', justifyContent: 'center' }}
            className="element-to-animate animate__animated animate__backInRight"
          >
            <img
              src={require('../../assets/images/Group 6 (1).png')}
              alt="EazeeBox Logo"
              style={{
                maxWidth: '100%',
                height: 'auto', // Maintains aspect ratio
                borderRadius: '10px', // Adds a softer look
              }}
            />
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto', // Allow dynamic height
          padding: '1rem', // Padding for smaller screens
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Adjusted for smaller screens
            borderBottom: '0px',
          }}
        >
          <Col
            xs={12}
            md={6}
            style={{ marginBottom: '1rem' }}
            className="element-to-animate animate__animated animate__backInRight"
          >
            <img
              src={require('../../assets/images/deals 1.png')}
              alt="EazeeBox Logo"
              style={{
                maxWidth: '100%', // Ensure image scales properly
                height: 'auto', // Maintain aspect ratio
                borderRadius: '10px', // Add a softer look
              }}
            />
          </Col>
          <Col xs={12} md={6}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                textAlign: 'left',
                color: '#ED324D',
                fontSize: '1.8rem', // Responsive font size
              }}
            >
              Exciting Deals
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '0.5rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1rem',
                lineHeight: '1.5',
              }}
            >
              Get ready to save big with the Eazeebox app! Discover exclusive offers and exciting
              deals on a wide range of electrical products and top brands. For no MOQ and best price
              come to eazeebox.
            </p>
            <Button
              className="element-to-animate animate__animated animate__backInRight"
              style={{
                backgroundColor: '#ED334E',
                border: 'none',
                borderRadius: '30px',
                padding: '10px 20px',
                fontSize: 'clamp(0.9rem, 2.5vw, 1.2rem)', // Responsive button text size
                fontWeight: 'bold',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth transition for hover effect
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#0035C3')} // Hover background color change
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#ED334E')} // Revert to original background
              onClick={playstoreURL}
            >
              {'Unlock Exciting Deals >'}
            </Button>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto', // Dynamic height for responsiveness
          padding: '1rem', // Padding for smaller devices
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better balance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={6} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                textAlign: 'left',
                color: '#0035C3',
                fontSize: '1.8rem', // Adjusted for readability
              }}
            >
              Eazee Credit
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '0.5rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1rem',
                lineHeight: '1.5',
              }}
            >
              With Eazee Credit, in partnership with Progcap, you can enjoy a hassle-free 30-day
              credit period to manage your purchases effectively. Stock up on all your electrical
              needs and pay later, giving you the flexibility to grow your business without
              financial strain.
            </p>
            <Button
              className="element-to-animate animate__animated animate__backInRight"
              style={{
                backgroundColor: '#ED334E',
                border: 'none',
                borderRadius: '30px',
                padding: '10px 20px',
                fontSize: 'clamp(0.9rem, 2.5vw, 1.2rem)', // Responsive button text size
                fontWeight: 'bold',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth transition for hover effect
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#0035C3')} // Hover background color change
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#ED334E')} // Revert to original background
              onClick={playstoreURL}
            >
              {'Get 30 day Credit >'}
            </Button>
          </Col>
          <Col xs={12} md={6} className="element-to-animate animate__animated animate__backInRight">
            <img
              src={require('../../assets/images/Credit 1.png')}
              alt="EazeeBox Logo"
              style={{
                maxWidth: '100%', // Ensures the image scales with its container
                height: 'auto', // Maintains aspect ratio
                borderRadius: '10px', // Softer edges for aesthetic appeal
              }}
            />
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller text for mobile */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Adjusted for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto', // Dynamic height for responsiveness
          borderBottom: '0px',
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Adjusted for smaller screens
            borderBottom: '0px',
          }}
        >
          <Col
            xs={12}
            md={6}
            style={{ marginBottom: '1rem' }}
            className="element-to-animate animate__animated animate__backInRight"
          >
            <img
              src={require('../../assets/images/Delivery boy 1.png')}
              alt="EazeeBox Logo"
              style={{
                maxWidth: '100%', // Ensures the image scales properly
                height: 'auto', // Maintains aspect ratio
                borderRadius: '10px', // Softer corners for aesthetics
              }}
            />
          </Col>
          <Col xs={12} md={6}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0 0 1rem 0',
                color: '#ED324D',
                textAlign: 'left',
                fontSize: '1.8rem', // Scales down on smaller devices
              }}
            >
              Express Delivery
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '0.5rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1rem',
                lineHeight: '1.5',
              }}
            >
              Experience lightning-fast service with Eazeebox&apos;s Express Delivery! We understand
              the urgency of your electrical needs, which is why we guarantee swift delivery within
              24 hours. With Eazeebox, you can trust us to keep your business running smoothly
              without delays!
            </p>
            <Button
              className="element-to-animate animate__animated animate__backInRight"
              style={{
                backgroundColor: '#ED334E',
                border: 'none',
                borderRadius: '30px',
                padding: '10px 20px',
                fontSize: 'clamp(0.9rem, 2.5vw, 1.2rem)',
                fontWeight: 'bold',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease, color 0.3s ease',
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#0035C3')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#ED334E')}
              onClick={playstoreURL}
            >
              Order Now &gt;
            </Button>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Adjusted for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller text for mobile */
        }
        button {
          width: 100%; /* Full-width button for small screens */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Adjusted for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        className="element-to-animate animate__animated animate__backInRight"
        size="lg"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '100%',
          marginTop: '15vh',
        }}
      >
        <Carousel
          prevIcon={
            <span
              style={{
                color: 'black',
                fontSize: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              &#x276E;
            </span>
          }
          nextIcon={
            <span
              style={{
                color: 'black',
                fontSize: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              &#x276F;
            </span>
          }
          style={{
            width: '100%', // Full width for the Carousel
            maxWidth: '1200px', // Restrict max width for larger screens
          }}
        >
          <Carousel.Item>
            <Image
              src={require('../../assets/images/Testominals.png')}
              rounded
              style={{
                width: '100%', // Image scales with container
                height: 'auto', // Maintain aspect ratio
                borderRadius: '10px', // Rounded corners for better aesthetics
              }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              src={require('../../assets/images/Testominals.png')}
              rounded
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
              }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              src={require('../../assets/images/Testominals.png')}
              rounded
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
              }}
            />
          </Carousel.Item>
        </Carousel>
        <style>
          {`
      @media (max-width: 768px) {
        .carousel {
          padding: 0; /* Remove excess padding on small screens */
        }
        img {
          border-radius: 5px; /* Slightly smaller rounded corners */
        }
      }
      @media (min-width: 768px) and (max-width: 1200px) {
        .carousel {
          max-width: 95%; /* Adjust carousel width for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Footer />
    </>
  )
}

export default EazeeBox
