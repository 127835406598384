import { Col } from 'react-bootstrap'
import React, { useRef, useEffect, useState } from 'react'

const VideoAutoplay = () => {
  // Create a ref for the first video
  const video1Ref = useRef(null)
  const [hasPermission, setHasPermission] = useState(false)

  // Function to check if the site has permission to play audio
  const checkPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'autoplay' })
      if (permissionStatus.state === 'granted') {
        setHasPermission(true)
      } else {
        setHasPermission(false)
      }
    } catch (error) {
      console.error('Permission check error:', error)
      setHasPermission(false)
    }
  }

  useEffect(() => {
    // Check if the site has permission on component mount
    checkPermission()

    // Function to handle intersection events
    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.target === video1Ref.current) {
          if (entry.isIntersecting) {
            // Play the first video when it enters the viewport
            video1Ref.current.play().catch((error) => {
              console.log('Autoplay failed:', error)
            })
          } else {
            // Pause the first video when it leaves the viewport
            video1Ref.current.pause()
          }
        }
      })
    }

    // Create intersection observer options
    const options = {
      threshold: 0.5, // Trigger when 50% of the video is visible
    }

    // Instantiate the intersection observer
    const observer = new IntersectionObserver(handleIntersection, options)

    // Start observing the first video only
    if (video1Ref.current) observer.observe(video1Ref.current)

    // Cleanup observer on component unmount
    return () => {
      if (video1Ref.current) observer.unobserve(video1Ref.current)
    }
  }, [])

  const handleVideoPlay = () => {
    // If permission is granted, play with sound
    if (hasPermission) {
      video1Ref.current.muted = false
    } else {
      // If no permission, mute the video
      video1Ref.current.muted = true
    }
  }

  return (
    <>
      <Col xs={12} md={6}>
        <div style={{ position: 'relative', overflow: 'hidden' }}>
          <video
            ref={video1Ref}
            width="100%"
            height="auto"
            style={{
              borderRadius: '10px',
              maxWidth: '100%',
              objectFit: 'cover',
              display: 'block',
            }}
            preload="auto"
            controls
            onPlay={handleVideoPlay}
            muted={!hasPermission} // Mute video if no permission
          >
            <source
              src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/app/videos/video1.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </Col>
      <Col xs={12} md={6} style={{ overflow: 'hidden' }}>
        <div style={{ position: 'relative', overflow: 'hidden' }}>
          <video
            width="100%"
            height="auto"
            style={{
              borderRadius: '10px',
              maxWidth: '100%',
              objectFit: 'cover',
              display: 'block',
            }}
            preload="auto"
            controls
            muted={!hasPermission} // Mute video if no permission
          >
            <source
              src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/app/videos/video2.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </Col>
    </>
  )
}

export default VideoAutoplay
