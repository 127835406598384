import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'
import { Navbar, Nav, Button, Container, Card, Row, Col, Image, Carousel } from 'react-bootstrap'
import backgroundImage from '../../assets/images/Eazeeboxbg.PNG'
import Footer from './Footer'
import backgroundImageFooter from '../../assets/images/Rectangle 13.png'
import '../../assets/eazeebox.css'
const OurStory = () => {
  const reelThumbnailUrl =
    'https://scontent.cdninstagram.com/v/t51.2885-15/359731991_1234567890_n.jpg?stp=dst-jpg&_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=abcd1234&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_Ax12345abcdef&oe=64A12345' // Replace with your reel thumbnail URL

  const reelLink = 'https://www.instagram.com/reel/C-ro8YOC91K/' // The link to the Instagram reel

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768) // Mobile threshold (adjust if needed)
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check when component mounts

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  function Header() {
    return (
      <Navbar expand="lg" className="bg-transparent">
        <Container>
          {/* Navbar Toggle Button (Visible on mobile) */}
          {isMobile && (
            <Navbar.Brand href="/" className="mx-auto">
              <img
                src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/Eazeebox+white+logo+3.png"
                className="d-block"
                style={{ maxWidth: '180px' }} // Adjust the size if needed
              />
            </Navbar.Brand>
          )}
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="d-lg-none" // Display only on mobile
            style={{ maxWidth: '60px' }}
          />

          {/* Collapsible Navbar */}
          <Navbar.Collapse
            id="navbarScroll"
            className="justify-content-center align-items-center w-100"
          >
            {/* Centered Logo for Desktop */}
            {!isMobile && (
              <Navbar.Brand href="#" className="mx-auto">
                <img
                  src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/Eazeebox+white+logo+3.png"
                  alt="Eazeebox Logo"
                  className="d-block"
                  style={{ maxWidth: '180px' }} // Adjust the size if needed
                />
              </Navbar.Brand>
            )}

            {/* Navigation Links (Centered) */}
            <Nav className="mx-auto">
              <Nav.Item>
                <Link to="/OurStory" className="nav-link text-white">
                  Our Story
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/Team" className="nav-link text-white">
                  Meet the Team
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/OurPartnerships" className="nav-link text-white disabled">
                  Our Partnerships
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/ContactUs" className="nav-link text-white disabled">
                  Contact Us
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Navbar */}
        {Header()}

        {/* Hero Section */}

        <Container
          size="lg"
          style={{
            border: '0px',
            boxShadow: 'none',
            justifyContent: 'center',
            textAlign: 'center',
            height: 'auto',
            padding: '1rem',
          }}
        >
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-start', // Align content to the left
              alignItems: 'flex-start', // Align items to the top
              borderBottom: '0px',
              padding: '1rem', // Optional: Add some padding
            }}
          >
            <Col
              xs={12} // Full width on small screens
              md={6} // Half width on medium screens
              style={{
                marginBottom: '1rem',
                maxWidth: '50%', // Ensure it doesn't exceed 50% on medium+ screens
                textAlign: 'left', // Align text to the left
              }}
            >
              <h1
                className="responsive-text"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  textAlign: 'left',
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontSize: 'clamp(1.5rem, 5vw, 3rem)', // Responsive font size
                }}
              >
                The
              </h1>
              <h1
                className="responsive-text"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  textAlign: 'left',
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontSize: 'clamp(1.5rem, 5vw, 3rem)', // Responsive font size
                }}
              >
                Eazeebox
              </h1>
              <h1
                className="responsive-text"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  textAlign: 'left',
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontSize: 'clamp(1.5rem, 5vw, 3rem)', // Responsive font size
                }}
              >
                Story
              </h1>
              <p
                className="responsive-p-text"
                style={{
                  margin: '0',
                  color: '#949494',
                  fontSize: 'clamp(0.9rem, 3vw, 1.2rem)', // Responsive font size
                  marginTop: '5rem',
                }}
              >
                Founded in 2021, Eazeebox began with a simple idea—to empower electrical retailers
                with eazee sourcing. Fast forward to 2025, and we’ve come a long way, becoming
                India’s leading platform for electrical retailers. This is just the beginning of the
                Eazeebox story!
              </p>
            </Col>
          </Row>

          <style>
            {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
          </style>
        </Container>
      </div>
      <div>
        <Container
          size="lg"
          style={{
            border: '0px',
            boxShadow: 'none',
            textAlign: 'left', // Default to left alignment
          }}
        >
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-start', // Align content to the left
              alignItems: 'flex-start', // Align items to the top
              marginTop: '15vh', // Adjust for layout spacing
              borderBottom: '0px',
            }}
          >
            <Col
              xs={12} // Full width on small screens
              md={6} // Half width on medium screens
              style={{
                marginBottom: '1rem',
                textAlign: 'left', // Ensure text aligns left
              }}
            >
              <h2
                className="service-responsive-text-header"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  color: '#0035C3',
                  textAlign: 'left', // Align text to the left
                  fontSize: 'clamp(1.5rem, 3vw, 1.8rem)', // Responsive font size
                }}
              >
                Idea in 2021
              </h2>
              <p
                className="service-responsive-text"
                style={{
                  margin: '0.5rem 0',
                  color: '#949494',
                  textAlign: 'left', // Align text to the left
                  fontSize: 'clamp(0.9rem, 2.5vw, 1rem)', // Responsive font size
                  lineHeight: '1.5',
                }}
              >
                Eazeebox started with four co-founders, three employees, and a big dream to change
                the FMEG industry. With no outside funding, we took a leap of faith not knowing
                where the journey would take us. What began as a small idea grew into a mission to
                support electrical retailers and become a market leader in the FMEG Industry.
              </p>
            </Col>
          </Row>
        </Container>

        <Container
          size="lg"
          style={{
            border: '0px',
            boxShadow: 'none',
            textAlign: 'left', // Align all text to the left
            height: 'auto', // Allow dynamic height
            padding: '1rem', // Spacing for responsiveness
          }}
        >
          {/* Section 1 */}
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-end', // Align content to the left
              alignItems: 'flex-end',
              marginTop: '15vh',
              borderBottom: '0px',
            }}
          >
            <Col xs={12} md={6}>
              <h2
                className="service-responsive-text-header"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  color: '#ED324D',
                  fontSize: 'clamp(1.5rem, 3vw, 1.8rem)', // Responsive font size
                }}
              >
                Business in 2022
              </h2>
              <p
                className="service-responsive-text"
                style={{
                  margin: '0.5rem 0',
                  color: '#949494',
                  textAlign: 'right',
                  fontSize: 'clamp(0.9rem, 2.5vw, 1rem)', // Responsive font size
                  lineHeight: '1.5',
                }}
              >
                Eazeebox started the new year & opened its account in January 2022 with its first
                invoice in Jayanagar, Bangalore. No platform or tech in place, the idea was to meet
                with retailers on the ground and begin the journey.
              </p>
            </Col>
          </Row>

          {/* Section 2 */}
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              marginTop: '15vh',
              borderBottom: '0px',
            }}
          >
            <Col xs={12} md={6}>
              <h2
                className="service-responsive-text-header"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  color: '#0035C3',
                  fontSize: 'clamp(1.5rem, 3vw, 1.8rem)',
                }}
              >
                First Financial Year
              </h2>
              <p
                className="service-responsive-text"
                style={{
                  margin: '0.5rem 0',
                  color: '#949494',
                  fontSize: 'clamp(0.9rem, 2.5vw, 1rem)',
                  lineHeight: '1.5',
                }}
              >
                Eazeebox finished FY22-23 with three full months in business & ending on a high with
                March-23 being the first month crossing 1Cr in sales.
              </p>
            </Col>
          </Row>

          {/* Section 3 */}
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              marginTop: '15vh',
              borderBottom: '0px',
            }}
          >
            <Col xs={12} md={6}>
              <h2
                className="service-responsive-text-header"
                style={{
                  fontWeight: 'bold',
                  margin: '0 0 1rem 0',
                  color: '#ED324D',
                  fontSize: 'clamp(1.5rem, 3vw, 1.8rem)',
                }}
              >
                2025: Only up from here
              </h2>
              <p
                className="service-responsive-text"
                style={{
                  margin: '0.5rem 0',
                  color: '#949494',
                  textAlign: 'right',
                  fontSize: 'clamp(0.9rem, 2.5vw, 1rem)',
                  lineHeight: '1.5',
                }}
              >
                Fast forward to 2025 and the Eazeebox team is now 25+ strong with warehouses and
                dark stores in Karnataka, Kerala, & Delhi. With 35+ brands and 20,000 SKUs in our
                catalogue, only one way to go from here -UP!
              </p>
            </Col>
          </Row>

          {/* CSS Styles */}
          <style>
            {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Adjust heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Adjust heading for tablets */
        }
      }
    `}
          </style>
        </Container>
      </div>

      <Footer />
    </>
  )
}

export default OurStory
