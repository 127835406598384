import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'
import { Navbar, Nav, Button, Container, Row, Col, Image, Carousel } from 'react-bootstrap'
import backgroundImage from '../../assets/images/Eazeeboxbg.PNG'
import Footer from './Footer'
import '../../assets/eazeebox.css'
const Privacy = () => {
  const [isMobile, setIsMobile] = useState(true)

  function Header() {
    return (
      <Navbar expand="lg" className="bg-transparent">
        <Container>
          {/* Navbar Toggle Button (Visible on mobile) */}
          {!isMobile && (
            <Navbar.Brand href="/" className="mx-auto">
              <img
                src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/Eazeebox+white+logo+3.png"
                className="d-block"
                style={{ maxWidth: '180px' }} // Adjust the size if needed
              />
            </Navbar.Brand>
          )}
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="d-lg-none" // Display only on mobile
            style={{ maxWidth: '60px' }}
          />

          {/* Collapsible Navbar */}
          <Navbar.Collapse
            id="navbarScroll"
            className="justify-content-center align-items-center w-100"
          >
            {/* Centered Logo for Desktop */}
            {isMobile && (
              <Navbar.Brand href="/" className="mx-auto">
                <img
                  src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/Eazeebox+white+logo+3.png"
                  alt="Eazeebox Logo"
                  className="d-block"
                  style={{ maxWidth: '180px' }} // Adjust the size if needed
                />
              </Navbar.Brand>
            )}

            {/* Navigation Links (Centered) */}
            <Nav className="mx-auto">
              <Nav.Item>
                <Link to="/OurStory" className="nav-link text-white">
                  Our Story
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/Team" className="nav-link text-white">
                  Meet the Team
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/OurPartnerships" className="nav-link text-white disabled">
                  Our Partnerships
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/ContactUs" className="nav-link text-white disabled">
                  Contact Us
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          minHeight: '90vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Navbar */}
        {Header()}

        {/* Hero Section */}

        <Container
          size="lg"
          style={{
            border: '0px',
            boxShadow: 'none',
            justifyContent: 'center',
            height: 'auto',
            padding: '1rem',
            minWidth: '100%', // Full width by default
          }}
        >
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: '0px',
            }}
          >
            {/* Text Column */}
            <Col
              className="p-4 mt-5"
              size="lg"
              style={{
                textAlign: 'left',
              }}
            >
              <h1
                className="responsive-text animate__animated animate__backInRight"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  textAlign: 'left',
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontSize: 'clamp(1.5rem, 5vw, 3rem)', // Responsive font size
                }}
              >
                Privacy
              </h1>
              <h1
                className="responsive-text animate__animated animate__backInRight"
                style={{
                  fontWeight: 'bold',
                  margin: '0',
                  textAlign: 'left',
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontSize: 'clamp(1.5rem, 5vw, 3rem)', // Responsive font size
                }}
              >
                Policy
              </h1>
            </Col>
          </Row>

          {/* Media queries for responsiveness across all devices */}
          <style>
            {`
      /* Large screens (desktops) */
      @media (min-width: 1200px) {
        .image-column img {
          max-width: 60%; /* Reduce image width */
        }
        .responsive-text {
          font-size: clamp(1.8rem, 5vw, 3.5rem); /* Slightly larger text on large screens */
        }
        .responsive-p-text {
          font-size: clamp(1rem, 3vw, 1.4rem); /* Slightly larger paragraph text */
        }
      }

      /* Tablets */
      @media (max-width: 1200px) and (min-width: 768px) {
        .image-column img {
          max-width: 60%; /* Reduce image width */
        }
        .responsive-text {
          font-size: clamp(1.5rem, 4.5vw, 3rem); /* Adjust text size */
        }
        .responsive-p-text {
          font-size: clamp(0.9rem, 3vw, 1.3rem); /* Adjust text size */
        }
      }

      /* Mobile devices */
      @media (max-width: 768px) {
        .image-column img {
          max-width: 90%; /* Reduce image width */
        }
        .responsive-text {
          font-size: clamp(1.2rem, 5vw, 2.5rem); /* Adjust text size */
        }
        .responsive-p-text {
          font-size: clamp(0.8rem, 3vw, 1.2rem); /* Adjust text size */
        }
        .responsive-button {
          font-size: clamp(0.8rem, 3vw, 1rem); /* Adjust button text size */
        }
      }

      /* Small mobile devices */
      @media (max-width: 480px) {
        .image-column img {
          max-width: 95%; /* Further reduce image width */
        }
        .responsive-text {
          font-size: clamp(1rem, 5vw, 2rem); /* Adjust text size */
        }
        .responsive-p-text {
          font-size: clamp(0.7rem, 3vw, 1.1rem); /* Adjust text size */
        }
      }
    `}
          </style>
        </Container>
      </div>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              Last Updated: 2025-01-05
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              Thank you for using our services. Your privacy is of utmost importance to us. This
              Privacy Policy describes how we collect, use, disclose, and safeguard your personal
              information when you visit our website and/or use our mobile application available on
              the Google Play Store. By accessing or using our Website or App, you agree to the
              terms of this Privacy Policy.
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              1. Privacy Protection
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              <b>Confidentiality:</b> We treat any personal information you share with us as
              confidential. We do not rent, sell, lend, or otherwise distribute your personal
              information to anyone for any reason, including your contact information and specific
              order details
            </p>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              <b>Scope:</b> This Privacy Policy applies solely to our Website and App. If our
              Website or App contains links to external sites, their respective privacy policies
              will govern your use of those sites.
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              2. Information Collected
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              <b>Basic Information for Orders:</b>
            </p>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              To process your orders, we require certain information, including:
            </p>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              First Name, Last Name
            </p>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              Address, City, Zip Code, State, Country
            </p>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              Email Address
            </p>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              Other information relevant to your order or account
            </p>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              <b>Additional Voluntary Information:</b>
            </p>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              We may occasionally request details about your interests or activities (e.g., sporting
              interests) to enhance your experience. Sharing this information is optional and not
              mandatory to place orders or use our services
            </p>

            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              Technical and Usage Information:
            </p>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              IP Address: When you visit our Website, our systems may record your IP address to help
              us deliver web pages upon request, customize our site, and track visitor geography. No
              Unauthorized Access: We strictly prohibit any unauthorized person or organization from
              accessing the information collected from you.
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              3. Information Modification
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              <b>Editing Your Profile:</b> You can modify or update your personal information at any
              time by accessing the “Edit Profile” or equivalent option on our Website or within the
              App.
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              4. Information Sharing
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              <b>Limited Sharing:</b> We do not rent, sell, barter, or give away your information to
              anyone.
              <b>Third Parties:</b> Certain information may be shared with courier services, payment
              processing companies, and vendors strictly for fulfilling your order.
              <b>Legal Requirements:</b> We may share information with law enforcement or other
              authorities if required by law, for fraud detection, or to ensure the safety of our
              Website, App, employees, users, and affiliates
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              5. Information Collected Automatically
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              We use persistent cookies, session cookies, and other tracking technologies (such as
              log files, clear GIFs, and Flash technologies) to: Store your username, password, and
              authentication tokens. Analyze usage of our Website and App. Customize our services to
              match your preferences. Control advertising and promotional content. Cookies:
              Persistent Cookies: Remain on your device even after you close your browser or App.
              Session Cookies: Temporary; they disappear after you close your browser or App. You
              can adjust your browser or device settings to refuse cookies or notify you when
              cookies are being sent. However, disabling cookies may affect certain features and
              functionality of our Website or App. Other Tracking Technologies: We may also use
              other internet technologies (e.g., clear GIFs in emails) to gauge the effectiveness of
              our communications. As we adopt additional technologies, we may collect further
              information through similar or additional methods.
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              6. Information Usage
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              Order Confirmation: We use your email address, phone number, and address to confirm
              your order and provide customer service updates. Newsletters and Updates: We may use
              your email or phone number to send newsletters, updates, or offers if you opt in.
              Legal and Dispute Requirements: We keep your information confidential unless
              disclosure is required by law or needed to resolve disputes.
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              7. Payment Security
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              No Credit Card Storage: We do not store your credit card details. All payment
              information is securely collected by our authorized payment gateways, which use
              advanced encryption and fraud protection to safeguard your data. Secure Servers: Once
              your payment information reaches our payment gateways, it resides on servers that are
              physically and electronically protected.
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              8. How We Protect Your Information
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              Security Measures: We implement commercially reasonable physical, managerial, and
              technical safeguards to maintain the integrity and security of your personal data. No
              Guarantee: While we strive to protect your information, no security system is
              impenetrable. We cannot guarantee absolute security of any information you share with
              us. Breach Notification: In the event of a security breach, we will promptly notify
              any affected individuals in accordance with applicable laws and regulations.
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              9. Your Choices About Your Information
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              Refusal to Provide Data: You may decline to submit personally identifiable
              information. However, certain functionalities (e.g., placing orders) may be limited.
              Profile Updates: You can update or correct your personal information at any time by
              logging into your account. Email Preferences: You may adjust your email subscription
              preferences or opt out of receiving marketing communications at any time
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              10. Changes to This Privacy Policy
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              Updates and Modifications: We may update this Privacy Policy to reflect changes in our
              practices. Any updates will be posted on this page with a new “Last Updated” date.
              Effective Date: Changes become effective when posted. Continued use of our Website or
              App after updates constitute your acceptance of the revised policy.
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Container
        size="lg"
        style={{
          border: '0px',
          boxShadow: 'none',
          justifyContent: 'center',
          textAlign: 'center',
          height: 'auto',
          padding: '1rem',
        }}
      >
        <Row
          className="element-to-animate"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15vh', // Reduced for better appearance on smaller screens
            borderBottom: '0px',
          }}
        >
          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <h2
              className="service-responsive-text-header element-to-animate animate__animated animate__backInRight"
              style={{
                fontWeight: 'bold',
                margin: '0',
                color: '#14AE5C',
                textAlign: 'left',
                fontSize: '3rem', // Adjusted for responsiveness
              }}
            >
              11. Additional Mobile App-Related Privacy Policy
            </h2>
            <p
              className="service-responsive-text element-to-animate animate__animated animate__backInRight"
              style={{
                margin: '2rem 0',
                color: '#949494',
                textAlign: 'left',
                fontSize: '1.8rem', // Adjusted font size for readability on smaller screens
                lineHeight: '1.5',
              }}
            >
              This section specifically addresses the Eazeebox – Retailer Business mobile
              application (“App”).
            </p>
          </Col>
        </Row>
        <style>
          {`
      @media (max-width: 768px) {
        h2.service-responsive-text-header {
          font-size: 1.5rem; /* Smaller heading for mobile */
        }
        p.service-responsive-text {
          font-size: 0.9rem; /* Smaller font for description */
        }
        button {
          width: 100%; /* Full-width button for mobile */
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        h2.service-responsive-text-header {
          font-size: 1.6rem; /* Slightly smaller heading for tablets */
        }
      }
    `}
        </style>
      </Container>

      <Footer />
    </>
  )
}

export default Privacy
