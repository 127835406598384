import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import { urlHost } from 'src/Api'
import axios from 'axios'
import truckIcon from '../../assets/images/truck.svg'
import { ListGroup, Badge } from 'react-bootstrap'
import Lottie from 'react-lottie'
import animationData from '../../assets/animation/AnimatedLoader.json'

const ShareableTracking = () => {
  const { uuid } = useParams()
  const [position, setPosition] = useState({ lat: 0, lng: 0 })
  const [VehicalNO, setVehicalNO] = useState('')
  const [Details, setDetails] = useState('')
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const locationInterval = 3000

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${urlHost}api/EncryptedDataListAPIView/${uuid}/`, {
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        },
        maxBodyLength: Infinity,
      })

      console.log(JSON.stringify(response.data))
      setVehicalNO(response.data?.data?.vehicalNo)
      setDetails(response.data?.data)
    } catch (error) {
      console.error('Error fetching details:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchPosition = async () => {
    if (!VehicalNO) return

    try {
      const response = await axios.get(`${urlHost}api/coordinatesLC/?VehicalNo=${VehicalNO}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('EB_X')}`,
        },
      })
      if (response.data?.length > 0) {
        setPosition({
          lat: parseFloat(response.data[0]?.lattitude),
          lng: parseFloat(response.data[0]?.longitude),
        })
        console.log(
          {
            lat: parseFloat(response.data[0]?.lattitude),
            lng: parseFloat(response.data[0]?.longitude),
          },
          'position',
        )
      }
    } catch (error) {
      console.error('Error fetching position:', error)
    }
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  useEffect(() => {
    if (VehicalNO) {
      const intervalId = setInterval(fetchPosition, locationInterval)
      return () => clearInterval(intervalId)
    }
  }, [VehicalNO])

  useEffect(() => {
    const checkGoogleMaps = setInterval(() => {
      if (window.google && window.google.maps) {
        setIsGoogleMapsLoaded(true)
        clearInterval(checkGoogleMaps)
      }
    }, 100)

    return () => clearInterval(checkGoogleMaps)
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div style={{ height: '70vh', width: '100%' }}>
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://global-eazee-box.s3.ap-south-1.amazonaws.com/static/MicrosoftTeams-image.png"
          alt="Eazeebox Logo"
          style={{ maxHeight: '10vh' }}
        />
      </div>
      {loading ? (
        <Lottie options={defaultOptions} height={60} width={60} />
      ) : (
        <>
          {isGoogleMapsLoaded && position && (
            <APIProvider>
              <Map
                apikey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
                mapId={process.env.NEXT_PUBLIC_MAP_ID}
                center={position}
                zoom={14}
                style={{ borderRadius: 15 }}
                options={{
                  zoomControl: true,
                  scrollwheel: true,
                  disableDoubleClickZoom: false,
                  draggable: true,
                  draggableCursor: 'move', // Adding this option
                  dragginCursor: 'move', // Adding this option
                }}
              >
                <Marker
                  position={position}
                  animation={window.google.maps.Animation.DROP}
                  icon={{
                    url: truckIcon,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                />
              </Map>
            </APIProvider>
          )}
          <ListGroup>
            <ListGroup.Item>
              <h2>Shipment details</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="list-item-label">
                <b>Store name:</b>
              </span>{' '}
              {Details?.customerName}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="list-item-label">
                <b>Shipment vehical number </b>:
              </span>{' '}
              {Details?.vehicalNo}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="list-item-label">
                <b>Delivery person mobile:</b>
              </span>{' '}
              {Details?.driveryMobile}
              {Details?.driveryMobile && (
                <a href={`tel:${Details.driveryMobile}`} className="btn btn-success m-2" size="sm">
                  Click to Call
                </a>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="list-item-label">
                <b>Invoice Ids:</b>
              </span>
              {Details?.InvoiceIds?.map((invoice, index) => (
                <Badge key={index} className="p-2 mx-1">
                  {invoice.value}
                </Badge>
              ))}
            </ListGroup.Item>
          </ListGroup>
        </>
      )}
    </div>
  )
}

export default ShareableTracking
